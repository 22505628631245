import "../scss/style-main.scss";

import "../js/instructor_convenience.js";
import "../js/helper-inst.js";
import "../js/random_background.js";

// class code input and submit button
var classCode = document.getElementById("joinCode");
var classCodeInput = document.querySelectorAll(".class-code-input");
var classCodeBtn = document.getElementsByClassName("class-code-btn")[0];

if (classCode) {
  // disable the submit button until code is complete
  function classCodeSubmit() {
    var classCodeReady = document.querySelectorAll(".class-code-input.ready");
    if (classCodeReady.length == 5) {
      classCodeBtn.classList.remove("disabled");      
    } else {
      classCodeBtn.classList.add("disabled");
    }
  }

  // check to see if input has value for submit button
  function inputReady() {
    var classCodeInputs = document.getElementsByClassName("class-code-input");
    for (var i = 0, classCodeInput; (classCodeInput = classCodeInputs[i++]); ) {
      if (classCodeInput.value === "") {
        classCodeInput.classList.remove("ready");
        classCodeSubmit();
      } else if (classCodeInput.value === " ") {
      } else {
        classCodeInput.classList.add("ready");
        classCodeSubmit();
      }
    }
  }

  // character input function
  function characterInput() {
    // typing
    classCode.addEventListener("input", function (e) {
      // move to next input
      var thisInput = e.target;
      var nextSibling = thisInput.nextElementSibling;
      if (thisInput.value) {
        nextSibling.focus();
        inputReady();
      }
    });

    // deleting
    classCode.addEventListener("keydown", function (e) {
      // move to previous input
      var thisKeydown = e.target;
      var previousSibling = thisKeydown.previousElementSibling;
      if (!thisKeydown.value && e.key === "Backspace" && previousSibling) {
        inputReady();
        previousSibling.focus();
      }
    });
  }

  // pasting
  classCode.addEventListener("paste", function (e) {
    // paste code across inputs
    if (e.target.type === "text") {
      var clipboardData = e.clipboardData.getData("Text");
      var newClipboardData = clipboardData.split(" ").join("");
      var letterCode = newClipboardData.replace(/[^a-z]/gi, "");
      letterCode = letterCode.split("");
      [].forEach.call(classCodeInput, (node, index) => {
        if (letterCode.length < 5) {
          // handle a paste shorter than 5 characters
          letterCode.push(" ");
          node.value = letterCode[index];
        } else {
          // handle a paste 5 characters or more
          node.value = letterCode[index];
          classCodeInput[4].focus();
          inputReady();
        }
      });
    }
  });

  // keypress event
  classCode.addEventListener("keypress", function (e) {
    // check for letters only - tab and enter also allowed
    var charCode = event.keyCode;
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      charCode == 8 || charCode == 13 ) 
    {
      characterInput();
    } else {
      e.preventDefault();
    }
  });
}
